<div class="main-header">
    <div class="logo">
        <img src="./assets/images/logo.png"  alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div class="d-flex align-items-center mt-3">
       <img width="150px" src="https://www.sena.co.th/content/images/2022-11-03/1667506112.png" alt="">
       <h3>{{projectData?.project_name_en}}</h3>
        <!-- Mega menu -->
        <div ngbDropdown class="mega-menu d-none d-md-block">
           
           
        </div>
        <!-- Search bar -->
       
    </div>

    <div style="margin: auto"></div>
 
    <div class="header-part-right">
       
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
       
  

        <div ngbDropdown [placement]="'bottom-right'" role="button" class="user col align-self-end ">
            <img  src="./assets/images/faces/1.png" id="userDropdown" ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                    <i class="i-Lock-User me-2"></i>{{userProfile?.first_name_last_name}}
                </div>
                <button class="dropdown-item" (click)="logout()">Log Out</button>
            </div>
        </div>

    </div> 

</div>