<div [ngClass]="{ open: navService.sidebarState.sidenavOpen }" class="sidebar-left rtl-ps-none"
  [perfectScrollbar]="{ suppressScrollX: true }">
  <div class="logo">
    <img src="./assets/images/logo.png" alt="" />
  </div>
  <ul class="navigation-left">
    <li [ngClass]="{ active: item.active }" (click)="onClickChangeActiveFlag(item)" class="nav-item lvl1"
      *ngFor="let item of nav">
      <div class="nav-item-hold" routerLink="{{ item.state }}" (click)="closeChildNav()"
        *ngIf="!item.disabled && item.type === 'link'">

        <span class="nav-text"> <i><img width="25px" [src]="item.icon" alt=""></i>&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name
          }}</span>
      </div>

      <div class="triangle" *ngIf="!item.disabled"></div>
    </li>
  </ul>
</div>