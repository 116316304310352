import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

  notifications: any[];
  user_id: string
  project_id: string
  userProfile: any
  projectData:any
  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private authService: AuthService,
    private router: Router, private localStoreService: LocalStoreService,private projectService:ProjectService


  ) {
    this.notifications = [
      {
        icon: 'i-Speach-Bubble-6',
        title: 'New message',
        badge: '3',
        text: 'TESTQQQQQ',
        time: new Date(),
        status: 'primary',
        link: '/chat'
      },

    ];
  }

  ngOnInit() {
    setTimeout(() => {
      this.user_id = this.localStoreService.getItem('user_id')
      this.project_id = this.localStoreService.getItem('project_id')
      this.gestUserProfile()
      this.gestProject()
    }, 300);
  }

  gestUserProfile() {
    this.authService.userById(this.user_id).subscribe(
      (res: any) => {
        this.userProfile = res
    
      }
    );
  }
    gestProject() {
    this.projectService.get(this.project_id).subscribe(
      (res: any) => {
        this.projectData = res
       
      }
    );
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    // item has child items
    if (!state.sidenavOpen && !state.childnavOpen
      && this.navService.selectedItem.type === 'dropDown') {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
    // item has no child items
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
    }
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      console.log('success');
      localStorage.clear();
      window.location.reload();
    });
  }

  profile() {
    this.router.navigate(['/user/profile']);


  }

}
